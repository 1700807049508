import type { IncomingMessage, ServerResponse } from "http"
import pathToRegexp from "path-to-regexp"
import { UAParser } from "ua-parser-js"
import { getHostUrl } from "@zb/common"
import routeWWW from "@zigbang/screens/routes/routes-www.json"
import { resolveApt, resolveItem, resolveSearch, resolveVilla } from "../redirect"

export const ssrMiddleware = ({ req, res }: { req: IncomingMessage; res: ServerResponse }) => {
	const url = getHostUrl(req)
	if (url === undefined) {
		return
	}

	if (/^\/search\//.test(url.pathname)) {
		// todo: cf 설정 필요
		// /search*
		const { path, search } = resolveSearch(url)
		if (path) {
			return res.writeHead(302, { Location: path + search }).end()
		}
	}

	if (/^\/items(1|2)\//.test(url.pathname)) {
		// todo: cf 설정 필요
		// /items1/*
		// /items2/*
		const redirectPath = resolveItem(url)
		if (redirectPath) {
			return res.writeHead(302, { Location: redirectPath }).end()
		}
	}

	if (/^\/apt\/(map|school|traffic|area|complex)\//.test(url.pathname) || /^\/danji\/items\//.test(url.pathname)) {
		// todo: cf 설정 필요
		// /apt/map/item*
		// /apt/school/*
		// /apt/traffic/*
		// /apt/agent/*
		// /apt/area/*
		// /apt/complex/*
		// /danji/items/*

		// todo: 확인필요
		// /apt/map/price > ? replacement가 있는지 확인 필요

		// /apt/map/popmove > /apt/map/popmove cf 설정 X
		// /apt/map/item > /home/apt/map cf 설정 X
		const redirectPath = resolveApt(url)
		if (redirectPath) {
			return res.writeHead(302, { Location: redirectPath }).end()
		}
	}

	if (/^\/villa\//.test(url.pathname)) {
		// todo: cf 설정 필요
		// /villa/*
		const redirectPath = resolveVilla(url)
		if (redirectPath) {
			return res.writeHead(302, { Location: redirectPath }).end()
		}
	}

	if (req.headers["user-agent"]?.includes("Googlebot")) {
		if (req.headers.host?.startsWith("www2")) {
			return res.writeHead(302, { Location: "/404" }).end()
		}

		return res
	}

	// Avoid redirecting to the mobile site when a user accesses the oauth callback or agent product page
	if (/^\/oauth\/callback/.test(url.pathname) || /^\/agent\/product/.test(url.pathname)) {
		return res
	}

	if (/^\/home\/RegisterInfo/.test(url.pathname)) {
		return res.writeHead(302, { Location: `${process.env.CEO_HOST}/intro` }).end()
	}

	const redirect_routes = routeWWW.filter((t: { redirect_to_mobile?: boolean }) => t.redirect_to_mobile)
	const hit = redirect_routes.some((t) => {
		const execResult: RegExpExecArray | null = pathToRegexp(t.url_path, []).exec(url.pathname)
		return !!execResult
	})

	const uaParser = new UAParser(req.headers["user-agent"] ?? "")
	const device = uaParser.getDevice()
	if (device.type === "mobile") {
		if (hit) {
			return res.writeHead(302, { Location: `${process.env.M_HOST}${url.pathname}` }).end()
		}
		return res
			.writeHead(302, { Location: "https://sp.zigbang.com/mobile/gateway/oneroom?mkt_source=organic%20search" })
			.end()
	}
}
