import Routes from "next-routes"
import pathToRegexp, { compile } from "path-to-regexp"
import qs from "qs"
import routesWWW from "./routeList"

const routes = new Routes()

routesWWW.forEach(({ name, url_path, pages }) => routes.add(name, url_path, pages))

export default routes
export const { Link, Router } = routes

// 서버의 asPath가 클라이언트와 다르게 나오는 경우에 이를 맞추기 위한 함수 (https://nextjs.org/docs/api-reference/next/router#router-object)
export const getPathForSSR = (pathName: string, query: { [key: string]: unknown }) => {
	const path = routesWWW.find(({ pages }) => `/${pages}` === pathName)?.url_path
	if (path) {
		const keys: pathToRegexp.Key[] = []
		const queryClone = { ...query }
		pathToRegexp(path, keys)
		keys.forEach((key) => {
			if (key.name in query) delete queryClone[key.name]
		})
		return `${compile(path)(query)}${qs.stringify(queryClone, { addQueryPrefix: true })}`
	}
}
