import React, { FC, PropsWithChildren, ReactNode } from "react"
import { sentry } from "@zigbang/screens/lib/sentry"
import ErrorFallback from "./ErrorFallback"

interface Props {
	fallback: ReactNode
}

interface State {
	hasError: boolean
	error?: Error
}

export class ErrorBoundary extends React.Component<PropsWithChildren<Props>, State> {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		console.error("[ErrorBoundary] catch error.", error, info)
		sentry.notify(error)
	}

	render() {
		if (this.state.hasError) {
			return this.props.fallback
		}
		return this.props.children
	}
}

export const ErrorBoundaryWithFallback: FC<PropsWithChildren> = ({ children }) => (
	<ErrorBoundary fallback={<ErrorFallback />}>{children}</ErrorBoundary>
)
