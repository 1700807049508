import styled from "styled-components"

export const Root = styled.div`
	flex: 1;
	display: flex;
`

export const MapArea = styled.div`
	flex: 1;
	background-color: #f1f1f1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-right: 1px solid #e1e1e1;
`

export const DetailArea = styled.div`
	flex: 0 0 400px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
`

export const ContentLayer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	z-index: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

export const Header = {
	Wrap: styled.div`
		display: flex;
		padding: 0 13px;
		text-align: center;
		min-height: 56px;
		align-items: center;
		border-bottom: 1px solid #e5e5e5;
	`,
	Left: styled.div`
		display: flex;
		flex: 0 0 28px;
		max-width: 30px;
		text-align: left;
	`,
	Center: styled.div`
		text-align: center;
		flex: 1;
	`,
	Right: styled.div`
		display: flex;
		max-width: 30px;
		flex: 0 0 28px;
		text-align: Right;
	`,
}
