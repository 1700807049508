import qs from "qs"

export const resolveSearch = (url: URL) => {
	const pathName = url.pathname
	const queryParams = qs.parse(url.search, { ignoreQueryPrefix: true })
	let redirectPath: string | null = "/"

	for (const paramKey of Object.keys(queryParams)) {
		if (paramKey === "lat") {
			queryParams.latitude = queryParams.lat
			delete queryParams.lat
		} else if (paramKey === "lng") {
			queryParams.longitude = queryParams.lng
			delete queryParams.lng
		}
	}

	switch (true) {
		case pathName.startsWith("/search/map"):
			redirectPath = pathName.replace("/search/map", "/home/oneroom/map")
			break
		case pathName.startsWith("/search/officetel"):
			redirectPath = pathName.replace("/search/officetel", "/home/officetel/map")
			break
		case pathName.startsWith("/search/danji"):
			redirectPath = pathName.replace("/search/danji", "/home/apt/map")
			break
		case pathName.startsWith("/search/villa"):
			redirectPath = pathName.replace("/search/villa", "/home/villa/map")
			break
		case pathName === "/search/postcode":
			redirectPath = null
			break
		default:
			break
	}
	return {
		path: redirectPath,
		search: qs.stringify(queryParams, { addQueryPrefix: true, encode: false }),
	}
}

export const resolveItem = (req: URL): string | undefined => {
	const pathName = req.pathname

	switch (true) {
		case pathName.startsWith("/items1/officetel/"):
			return pathName.replace("/items1/officetel/", "/home/officetel/items/")
		case pathName.startsWith("/items1/"):
			return pathName.replace("/items1/", "/home/oneroom/items/")
		case pathName.startsWith("/items2/") && pathName.endsWith("/amp"):
			return pathName.replace("/amp", "").replace("/items2/", "/home/oneroom/items/")
		case pathName.startsWith("/items2/"):
			return pathName.replace("/items2/", "/home/oneroom/items/")
		default:
			break
	}
}

export const resolveApt = (req: URL): string | undefined => {
	const pathName = req.pathname

	switch (true) {
		case pathName.startsWith("/apt/school/"):
			return pathName.replace("/apt/school/", "/home/apt/schools/")
		case pathName.startsWith("/apt/traffic/"):
			return pathName.replace("/apt/traffic/", "/home/apt/subways/")
		case pathName.startsWith("/apt/agent/"):
			return `${pathName.replace("/apt/agent/", "/profiles/")}?service_type=아파트`
		case pathName.startsWith("/apt/complex/"):
			return pathName.replace("/apt/complex/", "/home/apt/danjis/")
		case pathName.startsWith("/danji/items/"):
			return pathName.replace("/danji/items/", "/home/apt/items/")
		case pathName === "/apt/complex":
		case pathName.startsWith("/apt/map/item"):
		case pathName.startsWith("/apt/area/"):
			return "/home/apt/map"
		default:
			break
	}
}

export const resolveVilla = (req: URL): string | undefined => {
	const pathName = req.pathname

	switch (true) {
		case pathName.startsWith("/villa/info/"):
			return pathName.replace("/villa/info/", "/home/villa/building/")
		case pathName.startsWith("/villa/subway/"): {
			const subwayId = pathName.replace("/villa/subway/", "")
			return `/home/villa/subways/${subwayId}/items` // ?subway_name=강남구청역 처리 어떻게..
		}
		case pathName.startsWith("/villa/"):
			return pathName.replace("/villa/", "/home/villa/items/")
		default:
			break
	}
}
