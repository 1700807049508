import { NextRouter } from "next/router"
import React from "react"
import { ParamListBase, RouteProp } from "@react-navigation/native"
import { HomeAptMapOfferScreen } from "@zigbang/screens/home/apt/HomeAptMapOfferScreen"
import { HomeAptMapScreen } from "@zigbang/screens/home/apt/HomeAptMapScreen"
import { ReactNavigationMapper } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { SelectedMarker, WebMapStore } from "@zigbang/screens/lib/WebMapStore"
import { Rect } from "@zigbang/utils"

import { mapSubMenuStatus, MapType } from "../config"
import { ContentLayer, DetailArea, Root } from "./AptMapOfferLayoutStyle"
import MapWrapper from "./MapWrapper"
import { PageContainer } from "./PageContainer"

export class AptMapLayout extends React.Component<AptMapOfferLayoutProps> {
	static layoutOptions = {
		noFooter: true,
	}

	static getDerivedStateFromProps(props: any, state: any) {
		if (
			!props.danjiId &&
			!props.area_danji_id &&
			!props.areaDanjiId &&
			!props.ad_region_v1_id &&
			!props.school_code &&
			!props.pnu &&
			!props.localCode &&
			!props.locationData &&
			!props.item_id &&
			!props.aptProProfile
		) {
			return { ...state, selectMarkerData: undefined }
		}
		return state
	}

	static cloneInstance = (inst: any, additionalProps?: { [key: string]: any }) =>
		Object.assign(Object.create(Object.getPrototypeOf(inst)), inst, additionalProps)
	fakeNavigation = AptMapLayout.cloneInstance(this.props.navigation, {
		setParams: () => {
			//
		},
	})

	state: AptMapOfferLayoutState = {
		rect: undefined,
		selectMarkerData: undefined,
		visible: false,
		rightScreen: undefined,
		dragging: false,
	}
	dragging = false
	mapScreen = React.createRef()
	mapstore = new WebMapStore()

	render() {
		const { rightScreen: Component, router, navigationRoute, routeName, ...restProps } = this.props
		if (!router || !router.pathname) {
			return null
		}
		let { query } = this.props
		if (this.props.router.pathname === "/home/apt_map_offer" || this.props.router.pathname === "/home/apt_map") {
			query = { ...this.props.query, ...this.state.rect, dragging: this.state.dragging }
		}
		const MapComponent = mapSubMenuStatus.get() === MapType.offer ? HomeAptMapOfferScreen : HomeAptMapScreen
		const pageContainer = (
			// @ts-ignore
			<PageContainer
				{...{ router, Component, routeName, ...restProps, ...query }}
				updateMarker={this.updateMarker}
				selectMarker={this.state.selectMarkerData}
			/>
		)
		return (
			<Root>
				<MapWrapper
					MapComponent={MapComponent}
					innerRef={this.mapScreen}
					navigation={this.fakeNavigation}
					route={navigationRoute}
					onPanDrag={this.onPanDrag}
					onRegionChangeComplete={this.onRegionChangeComplete}
					selectMarkerData={this.state.selectMarkerData}
					detailExists={!Component.isEmpty}
				/>
				{Component.isEmpty ? null : (
					<DetailArea>
						<ContentLayer>{pageContainer}</ContentLayer>
					</DetailArea>
				)}
			</Root>
		)
	}

	onRegionChangeComplete = (rect: Rect) => {
		if (this.state.dragging) {
			const map = mapSubMenuStatus.get() === MapType.offer ? "HomeAptMapOffer" : "HomeAptMap"
			if (
				this.props.router.pathname !== "/home/apt_map_offer" &&
				this.props.router.pathname !== "/home/apt_map"
			) {
				this.props.navigation.push(map)
			}
		}
		this.setState({ rect, dragging: false })
	}

	onPanDrag = () => {
		this.setState({ dragging: true })
	}

	updateMarker = ({ latitude, longitude, id, markerType, fixedZoom, zoom }: SelectedMarker) => {
		this.setState({
			selectMarkerData: {
				latitude,
				longitude,
				id,
				markerType,
				fixedZoom,
				zoom,
			},
		})
	}
	setListParams = (params: { [key: string]: any }) => this.props.navigation.setParams({ list: params })

	setDetailParams = (params: { [key: string]: any }) =>
		this.props.navigation.setParams({ detail: { title: params.name || "로딩 중 입니다..." } })
}
interface AptMapOfferLayoutProps {
	navigation: ReactNavigationMapper
	rightScreen: React.ComponentType<any> & { isEmpty?: boolean }
	router: NextRouter
	routeName: string
	query: { [key: string]: unknown }
	asPath: string
	area_danji_id?: string
	navigationRoute: RouteProp<ParamListBase>
}

interface AptMapOfferLayoutState {
	rect?: Rect
	selectMarkerData?: SelectedMarker
	visible: boolean
	rightScreen?: React.ComponentClass<any>
	filterModel?: {}
	dragging: boolean
}
