const routesWWW = require("@zigbang/screens/src/routes/routes-www.json")

const routingTable = routesWWW.concat(
	{
		url_path: "/oauth/callback",
		pages: "oauth_callback",
		name: "OAuthCallback",
	},
	{
		url_path: "/agent/product/:service(oneroom|villa|officetel)",
		pages: "agent/product/service",
		name: "AgentProduct",
	},
	{
		url_path: "/sitemap(.*).xml",
		pages: "sitemap.xml",
		name: "sitemap",
	}
)

module.exports = routingTable
