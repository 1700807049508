import React, { useCallback, useEffect, useRef, useState } from "react"
import * as Zuix2 from "@zigbang/zuix2"
import styled from "styled-components"
import { View } from "react-native"
import { Url } from "@zigbang/utils"
import {
	AccountDialogParams,
	subscribeAccountDialogEvent,
	unsubscribeAccountDialogEvent,
} from "@zigbang/screens/src/lib/Account/controlAccountDialog"
import { 유저정보 } from "@zigbang/screens/lib/Account"
import { Constants } from "@zigbang/screens/lib"
import { StorageManager } from "@zigbang/screens"

interface AccountDialogProps {
	updateUserInfo(): Promise<유저정보>
}

export enum PageTitle {
	SETTING = "SETTING",
	PROFILE = "PROFILE",
	MODIFY_NAME = "MODIFY_NAME",
	MODIFY_NICKNAME = "MODIFY_NICKNAME",
	MODIFY_PASSWORD = "MODIFY_PASSWORD",
	MODIFY_PHONE = "MODIFY_PHONE",
	MEMBERSHIP_WITHDRAWAL = "MEMBERSHIP_WITHDRAWAL",
	PHONE_CERTIFICATE = "PHONE_CERTIFICATE",
	POLICIES = "POLICIES",
	MARKETING_POLICY = "MARKETING_POLICY",
}

export enum CertificationReceiveMessageActions {
	CERTIFICATION_RESULT = "CERTIFICATION_RESULT",
	CERTIFICATION_CANCEL = "CERTIFICATION_CANCEL",
	CERTIFICATION_ERROR = "CERTIFICATION_ERROR",
}

const SettingDialog: React.FC<AccountDialogProps> = ({ updateUserInfo }) => {
	const [dialogVisible, setDialogVisible] = useState(false)
	const defaultUri = `${process.env.ACCOUNT_HOST}/v2/my/settings?platform=${process.env.PLATFORM}`
	const [uri, setUri] = useState<string | undefined>(defaultUri)
	const [topBarTitle, setTopbarTitle] = useState("설정")
	const webviewRef = useRef<HTMLIFrameElement>(null)

	const [snackBarOption, setSnackBarOption] = useState<Zuix2.SnackBarProps>({
		visible: false,
		title: "",
		onClose: () => null,
	})
	const { showSnackBar } = Zuix2.useSnackBar()
	const phoneNumberChangeCallbackFunc = useRef<{
		success?: (phone: string) => void
		close?: (phone?: string) => void
	}>()

	const showCustomSnackBar = useCallback(
		(title: string) => {
			setSnackBarOption({
				...snackBarOption,
				visible: true,
				title,
			})
		},
		[snackBarOption]
	)

	const changeToolbarTitle = useCallback((value) => {
		let title = ""

		switch (value) {
			case PageTitle.PROFILE:
				title = "내 정보"
				break
			case PageTitle.MODIFY_NAME:
				title = "이름 변경"
				break
			case PageTitle.MODIFY_NICKNAME:
				title = "닉네임 변경"
				break
			case PageTitle.MODIFY_PASSWORD:
				title = "비밀번호 변경"
				break
			case PageTitle.MEMBERSHIP_WITHDRAWAL:
				title = "회원탈퇴하기"
				break
			case PageTitle.POLICIES:
				title = "약관 및 정책"
				break
			case PageTitle.MARKETING_POLICY:
				title = "부동산 정보 및 이벤트 수신"
				break
			case PageTitle.PHONE_CERTIFICATE:
				title = "번호 인증"
				break
			case PageTitle.MODIFY_PHONE:
				title = "휴대폰 변경"
				break
			case PageTitle.SETTING:
			default:
				title = "설정"
		}
		setTopbarTitle(title)
	}, [])

	const closeDialog = useCallback(() => {
		if (phoneNumberChangeCallbackFunc.current?.close) {
			phoneNumberChangeCallbackFunc.current?.close()
			phoneNumberChangeCallbackFunc.current = undefined
		}
		setDialogVisible(false)
		setUri(undefined)
		changeToolbarTitle("")
	}, [changeToolbarTitle])

	const changeUrl = useCallback((url: string) => {
		setUri(url)
	}, [])

	const reloadCurrentLocation = useCallback(() => {
		closeDialog()
		window.location.reload()
	}, [closeDialog])

	const openWindow = useCallback(
		({ url, _options }) => {
			if (_options === "_self") {
				changeUrl(`${process.env.ACCOUNT_HOST}${url}`)
				return
			}
			window.open(url)
		},
		[changeUrl]
	)

	const afterUserInfoChange = useCallback(async () => {
		try {
			const userInfo = await updateUserInfo()
			await StorageManager.getInstance().set(Constants.Keys.KEY_CONTACT_MESSAGE_PHONE_NUMBER, userInfo.phone)
		} catch (e) {
			console.log(e)
		}
	}, [updateUserInfo])

	const invokePhoneNumberChangeCallback = useCallback(async () => {
		try {
			if (!phoneNumberChangeCallbackFunc.current?.success) {
				return
			}
			const phoneNumber = await StorageManager.getInstance().get(
				Constants.Keys.KEY_CONTACT_MESSAGE_PHONE_NUMBER,
				""
			)
			phoneNumberChangeCallbackFunc.current.success(phoneNumber)
			phoneNumberChangeCallbackFunc.current = undefined
		} catch (e) {
			console.log(e)
		}
	}, [])

	const onMessageForCI = useCallback(
		async (action: CertificationReceiveMessageActions) => {
			switch (action) {
				case CertificationReceiveMessageActions.CERTIFICATION_CANCEL:
					phoneNumberChangeCallbackFunc.current?.close?.()
					phoneNumberChangeCallbackFunc.current = undefined
					break
				case CertificationReceiveMessageActions.CERTIFICATION_ERROR:
					showCustomSnackBar("잠시 후 다시 시도해주세요.")
					break
				case CertificationReceiveMessageActions.CERTIFICATION_RESULT:
					await afterUserInfoChange()
					invokePhoneNumberChangeCallback()
					break
				default:
			}
			setUri(`${process.env.ACCOUNT_HOST}/v2/my/profile`)
		},
		[afterUserInfoChange, invokePhoneNumberChangeCallback, showCustomSnackBar]
	)

	const onMessage = useCallback(
		async (event) => {
			let name = ""
			let params: { [key: string]: any } = {}

			try {
				if (typeof event.data === "string") {
					const data = JSON.parse(event.data)
					// ci 인증일 때

					if (data.action) {
						onMessageForCI(data.action)
						return
					}
					name = data.name
					params = data.params
				} else {
					name = event.data.name
					params = event.data.params
				}

				switch (name) {
					case "CHANGE_PAGE_TITLE":
						changeToolbarTitle(params.value)
						break
					case "OPEN_INAPP_BROWSER":
						openWindow({ url: params?.url, _options: params?._options })
						break
					case "NAVIGATE":
						changeUrl(
							Url.updateQueryStringParams(`${process.env.ACCOUNT_HOST}${params.path}`, {
								...params.params,
							})
						)
						break
					case "LOGOUT":
						showSnackBar({
							title: "로그아웃되었습니다.",
						})
						reloadCurrentLocation()
						break
					case "MEMBERSHIP_WITHDRAWAL":
						reloadCurrentLocation()
						break
					case "PERMISSION_DENIED":
						reloadCurrentLocation()
						break
					case "GO_BACK":
						closeDialog()
						break
					case "UPDATE_MY_PROFILE":
						await afterUserInfoChange()
						invokePhoneNumberChangeCallback()
						break
					case "OPEN_CI_PHONE_AUTH":
						setUri(Url.updateQueryStringParams(`${process.env.ACCOUNT_HOST}${params.url}`, params.params))
						break
					default:
				}
			} catch (e) {
				console.warn(e)
			}
		},
		[
			afterUserInfoChange,
			changeToolbarTitle,
			changeUrl,
			closeDialog,
			invokePhoneNumberChangeCallback,
			onMessageForCI,
			openWindow,
			reloadCurrentLocation,
			showSnackBar,
		]
	)

	const handleAccountDialogEvent = useCallback(
		({ visible, url, onSuccessPhoneNumberChange, onClosePhoneNumberChange }: AccountDialogParams) => {
			setDialogVisible(visible)
			if (url) {
				setUri(url)
			}
			if (onSuccessPhoneNumberChange) {
				phoneNumberChangeCallbackFunc.current = {
					success: onSuccessPhoneNumberChange,
					close: phoneNumberChangeCallbackFunc.current?.close,
				}
			}
			if (onClosePhoneNumberChange) {
				phoneNumberChangeCallbackFunc.current = {
					success: phoneNumberChangeCallbackFunc.current?.success,
					close: onClosePhoneNumberChange,
				}
			}
		},
		[]
	)

	useEffect(() => {
		subscribeAccountDialogEvent(handleAccountDialogEvent)

		return () => {
			unsubscribeAccountDialogEvent(handleAccountDialogEvent)
		}
	}, [handleAccountDialogEvent])

	useEffect(() => {
		subscribeAccountDialogEvent(handleAccountDialogEvent)

		return () => {
			unsubscribeAccountDialogEvent(handleAccountDialogEvent)
		}
	}, [handleAccountDialogEvent])

	useEffect(() => {
		if (!dialogVisible) {
			return
		}
		const preventGoBack = () => {
			history.pushState(null, "", location.href)
			closeDialog()
		}

		history.pushState(null, "", location.href)
		window.addEventListener("popstate", preventGoBack)

		// eslint-disable-next-line consistent-return
		return () => window.removeEventListener("popstate", preventGoBack)
	}, [closeDialog, dialogVisible])

	useEffect(() => {
		if (!dialogVisible) {
			return
		}
		window.addEventListener("message", onMessage)

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener("message", onMessage)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogVisible])

	if (!dialogVisible || !uri) {
		return null
	}

	return (
		<Screen.Dim>
			<Screen.Container>
				<Zuix2.TopBar
					right={<Zuix2.Icon width={28} height={28} shape="Close" onPress={closeDialog} />}
					title={{ children: topBarTitle, textAlign: "center" }}
					bg={Zuix2.Color.white}
					divider={false}
					titleAlign="center"
				/>
				<iframe ref={webviewRef} key={uri} src={uri} style={{ width: "100%", height: "100%" }} />
				<Zuix2.SnackBar
					title={snackBarOption.title}
					visible={snackBarOption.visible}
					onClose={snackBarOption.onClose}
					style={{ position: "absolute" }}
				/>
			</Screen.Container>
		</Screen.Dim>
	)
}
const Screen = {
	Dim: styled(View)`
		display: absolute;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999;
		background-color: rgba(0, 0, 0, 0.8);
	`,
	Container: styled(View)`
		position: relative;
		width: 380px;
		height: 570px;
		overflow-y: auto;
		box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.32);
		border-radius: 6px;
		background-color: ${Zuix2.Color.white};
	`,
	LoadingContainer: styled(View)`
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: calc(-52 / 2) px;
		left: 0;
		right: 0;
		height: 100%;
		z-index: 2;
		background-color: ${Zuix2.Color.white};
	`,
}
export default SettingDialog
