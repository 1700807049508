import { Button, Color, Empty, Image } from "@zigbang/zuix2"
import { useRouter } from "next/router"
import React, { FC } from "react"
import { View } from "react-native"
import styled from "styled-components"

const errorIcon = require("@zigbang/screens/static/error/error_icon.png")

const ErrorFallback: FC = () => {
	const router = useRouter()
	const reload = () => {
		router.reload()
	}

	return (
		<Boundary>
			<Empty
				bottom={
					<Button
						size="32"
						theme="lineGray90"
						status="normal"
						title={{
							size: "13",
							weight: "medium",
							children: "새로 고침",
							color: Color.gray10,
						}}
						onPress={reload}
					/>
				}
				subtitle="하단 버튼을 눌러 웹을 다시 시작할 수 있습니다."
				title="일시적인 오류입니다"
				top={<Image width={60} height={64} source={errorIcon} />}
			/>
		</Boundary>
	)
}

export default ErrorFallback

const Boundary = styled(View)`
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: white;
`
