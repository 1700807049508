import { useEffect } from "react"
import { ParamListBase, RouteProp } from "@react-navigation/native"
import { ReactNavigationMapper, SelectedMarker } from "@zigbang/screens"
import { NoSSR } from "@zigbang/screens/lib/NoSSR"
import { Rect } from "@zigbang/utils"

import { MapArea } from "./AptMapOfferLayoutStyle"

interface MapWrapperProps {
	MapComponent: React.ComponentType<any>
	innerRef: React.Ref<unknown>
	navigation: ReactNavigationMapper
	route: RouteProp<ParamListBase>
	onPanDrag: () => void
	onRegionChangeComplete: (rect: Rect) => void
	onClusterPress?: () => void
	pathname?: string
	selectMarkerData?: SelectedMarker
	detailExists?: boolean
	infoMarker?: { element: JSX.Element; coordinate: { latitude: number; longitude: number }; key: number | string }
}

const MapWrapper: React.FC<MapWrapperProps> = ({ MapComponent, innerRef, ...props }) => {
	useEffect(() => {
		props.navigation.emitEvent("focus")
	}, [props.navigation])

	return (
		<MapArea>
			<NoSSR>
				<MapComponent ref={innerRef} {...props} />
			</NoSSR>
		</MapArea>
	)
}

export default MapWrapper
