/* eslint-disable @typescript-eslint/no-unused-vars */
import { ParsedUrlQuery } from "querystring"
import React from "react"
import type { StackNavigationOptions } from "@react-navigation/stack"
import { Rect } from "@zigbang/utils"
import _ from "lodash"
import { NextRouter } from "next/router"
import { LayoutOptions, NavRouter } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { SelectedMarker } from "@zigbang/screens/lib/WebMapStore"
import { Wrap } from "../../Wrap"
import { Router } from "../../../routes"
import { HistoryObject, PageStatus, Stack } from "../../Stack"

const URL_FROM_SELECTED_MARKER = [
	{ url: "/home/apt/danjis", param: "area_danji_id" },
	{ url: "/home/apt/locals", param: "pnu" },
	{ url: "/home/officetel/danjis", param: "area_danji_id" },
]

export class PageContainer extends React.Component<PageProps> {
	stack = new Stack(this.props.router)
	layerElement: React.RefObject<HTMLDivElement> = React.createRef()
	selectMarkerDataHistory: SelectedMarker[] = []
	shouldComponentUpdate(nextProps: PageProps) {
		const { Component, router, routeName, ...params } = this.props

		const { Component: nextC, router: nextR, routeName: nextRname, ...nextParams } = nextProps

		return this.props.Component !== nextProps.Component || !_.isEqual(params, nextParams)
	}
	componentDidMount() {
		this.stack.didMount()
	}
	componentWillUnmount() {
		this.stack.willUnmount()
	}
	pushCallback = (historyObj?: HistoryObject) => {
		const param = URL_FROM_SELECTED_MARKER.filter((data) => historyObj?.historyState?.as.includes(data.url))?.map(
			(data) => data.param
		)?.[0]
		if (this.props?.selectMarker && historyObj?.historyState?.url && param) {
			const selectedMarker = { ...this.props.selectMarker }
			const url = new URL(`${process.env.CURRENT_DOMAIN}/${historyObj.historyState.url}`)
			const id = url.searchParams.get(param)
			if (selectedMarker.id == id) {
				this.selectMarkerDataHistory.push(selectedMarker)
			}
		}
	}

	popCallback = (historyObj?: HistoryObject) => {
		const param = URL_FROM_SELECTED_MARKER.filter((data) => historyObj?.historyState?.as.includes(data.url))?.map(
			(data) => data.param
		)?.[0]
		if (historyObj?.historyState?.url && param) {
			const url = new URL(`${process.env.CURRENT_DOMAIN}/${historyObj.historyState.url}`)
			const id = url.searchParams.get(param)
			if (this.props.selectMarker?.id != id) {
				const prevMarkerIndex = _.findLastIndex(this.selectMarkerDataHistory, (marker) => marker.id == id)
				if (prevMarkerIndex >= 0) {
					this.props.updateMarker(this.selectMarkerDataHistory[prevMarkerIndex])
				}
				this.selectMarkerDataHistory.splice(prevMarkerIndex)
			}
		}
	}

	render() {
		const { Component, router, routeName, ...params } = this.props
		// const { params } = this.state
		const createComponent = (key: number, status: PageStatus) => {
			const ref = React.createRef()
			const pageProps = params
			const props = { key, ref, params, status, Component, router, routeName, pageProps }
			// @ts-ignore
			const component = <Wrap {...props} nextRouter={Router} />
			return { ref, component }
		}
		return this.stack.render(createComponent, this.popCallback, this.pushCallback)
	}
}

interface PageProps {
	Component: React.ComponentType<any> & {
		navigationOptions: StackNavigationOptions
		layoutOptions: LayoutOptions
	}
	router: NextRouter
	routeName: string
	asPath: string
	rect?: Rect
	query: { [key: string]: unknown }
	updateMarker: (data: SelectedMarker) => void
	selectMarker?: SelectedMarker
	onHoverItemIn?: (obj: {
		element: JSX.Element
		coordinate: { latitude: number; longitude: number }
		key: string | number
	}) => void
	onHoverItemOut?: () => void
}

export interface WrapProps {
	query: { [key: string]: unknown }
	asPath: string
	routeName: string
	Component: React.ComponentType<any> & { navigationOptions: StackNavigationOptions }
	nextRouter: NavRouter
}
interface PageState {
	params: ParsedUrlQuery
	_asPath: string
}
