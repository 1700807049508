import { ParsedUrlQuery } from "querystring"
import _ from "lodash"
import Router, { NextRouter } from "next/router"
import React from "react"
import { Animated, Easing, View } from "react-native"
import styled from "styled-components"
import type { HeaderTitleProps } from "@react-navigation/elements"
import { ToolbarItem } from "@zigbang/screens/components/layout/ToolbarItem"
import { Modal2Wrapper } from "@zigbang/screens/lib/Modal"
import { NavigationProvider } from "@zigbang/screens/lib/navigation/NavigationContext"
import { ReactNavigationMapper, ScreenNavigationOptions } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { ViewDimension } from "@zigbang/screens/lib/ViewDimension"

import { overrideNavigationV4 } from "@zigbang/screens/lib/navigation/ReactNavigationMapper/navigationV4"
import { PageStatus } from "./Stack"
import { ErrorBoundaryWithFallback } from "./components/mapLayout/ErrorBoundary"

export class Wrap extends React.Component<any> {
	state: { pageStatus: PageStatus } = {
		pageStatus: this.props.status,
	}
	aniPosition = {
		willBlur: 0,
		blur: -0.5,
		blurred: -0.5,
		focus: 0,
		focused: 0,
		willFocus: 1,
		willFocusFormBack: -0.5,
		ready: 1,
		gone: 1,
		removed: 1,
		willGone: 0,
	}
	anim = new Animated.Value(this.aniPosition[this.state.pageStatus])
	interpolate = {
		inputRange: [-1, 1],
		outputRange: ["-100%", "100%"],
	}
	animate(prevStatus?: any) {
		if (prevStatus && prevStatus === this.state.pageStatus) {
			return
		}
		Animated.timing(this.anim, {
			toValue: this.aniPosition[this.state.pageStatus],
			duration: 200,
			easing: Easing.inOut(Easing.ease),
			useNativeDriver: false,
		}).start(({ finished }) => {
			if (finished) {
				if (this.state.pageStatus === PageStatus.gone) {
					this.setState({ pageStatus: PageStatus.removed })
				}
				if (this.state.pageStatus === PageStatus.focus) {
					this.setState({ pageStatus: PageStatus.focused })
				}
			}
		})
	}
	componentDidMount() {
		// this.animate()
		this.readyToFocus()
	}
	componentDidUpdate() {
		this.readyToFocus()
	}
	readyToFocus = () => {
		if (this.state.pageStatus === PageStatus.willBlur) {
			this.setState({ pageStatus: PageStatus.blurred })
		}
		if (this.state.pageStatus === PageStatus.willFocusFormBack) {
			this.setState({ pageStatus: PageStatus.focused })
		}
		if (this.state.pageStatus === PageStatus.willFocus) {
			this.setState({ pageStatus: PageStatus.focused })
		}
		if (this.state.pageStatus === PageStatus.willGone) {
			this.setState({ pageStatus: PageStatus.removed })
		}
	}
	render() {
		if (this.state.pageStatus === PageStatus.removed) {
			return null
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { status, routeName, ...props } = this.props
		// @ts-ignore
		const wrapper = (
			<PageWrapper>
				<InnerWrap {...props} pageStatus={this.state.pageStatus} routeName={routeName} />
			</PageWrapper>
		)
		return wrapper
	}
}

class InnerWrap extends React.Component<any> {
	static getDerivedStateFromProps(
		nextProps: { params: ParsedUrlQuery; router: NextRouter },
		state: { params: ParsedUrlQuery; _prevParams: ParsedUrlQuery }
	) {
		const { params } = nextProps
		if (!_.isEqual(state._prevParams, nextProps.params)) {
			return { _prevParams: params }
		} // 라우팅이 바뀌었을때는 경로를 기존 state 날림
		return null
	}
	layerElement: React.RefObject<HTMLDivElement> = React.createRef()
	state: { params?: ParsedUrlQuery }
	private navigation!: ReactNavigationMapper
	private firstNavigation: ReactNavigationMapper
	private navigationOptions!: ScreenNavigationOptions
	drawerRef = React.createRef()

	constructor(props: any) {
		super(props)
		this.state = { params: props.params }
		this.setNavigation(props, props.params, props.routeName)
		this.firstNavigation = this.navigation
	}
	updateNavigationState = (params: any) => {
		this.setState({ params })
		return true
	}
	shouldComponentUpdate(nextProps: any, nextState: { params: ParsedUrlQuery }) {
		const isParamsFromPropsEqual = _.isEqual(this.props.params, nextProps.params)
		const isParamsFromStateEqual = _.isEqual(this.state.params, nextState.params)
		const isPropsAndStateEqual = isParamsFromPropsEqual && isParamsFromStateEqual

		// console.log("## shouldComponentUpdate this.props.params", this.props.routeName, this.props.params)
		// console.log("## shouldComponentUpdate nextProps.params", this.props.routeName, nextProps.params)
		// console.log("## shouldComponentUpdate this.state.params", this.props.routeName, this.state.params)
		// console.log("## shouldComponentUpdate nextState.params", this.props.routeName, nextState.params)
		// console.log("## isParamsFromPropsEqual", this.props.routeName, isParamsFromPropsEqual)
		// console.log("## isParamsFromStateEqual", this.props.routeName, isParamsFromStateEqual)
		// console.log("## isPropsAndStateEqual", this.props.routeName, isPropsAndStateEqual)
		// console.log("## isPropsAndStateEqual", this.props.routeName, isPropsAndStateEqual)
		// console.log("## before navigation ", this.props.routeName, this.navigation)

		if (!isParamsFromPropsEqual) {
			this.setNavigation(nextProps, { ...nextState.params, ...nextProps?.params }, this.props.routeName)
		}

		if (!isParamsFromStateEqual) {
			this.setNavigation(nextProps, { ...nextProps?.params, ...nextState.params }, this.props.routeName)
		}

		// console.log("## after navigation ", this.props.routeName, this.navigation)

		const currStatus = this.props.pageStatus
		const nextStatus = nextProps.pageStatus
		if (
			(nextStatus === PageStatus.willBlur && currStatus !== PageStatus.willBlur) ||
			(nextStatus === PageStatus.willGone && currStatus !== PageStatus.willGone)
		) {
			this.firstNavigation.emitEvent("blur")
		}
		if (
			(nextStatus === PageStatus.willFocus && currStatus !== PageStatus.willFocus) ||
			(nextStatus === PageStatus.willFocusFormBack && currStatus !== PageStatus.willFocusFormBack)
		) {
			this.firstNavigation.emitEvent("focus")
		}
		if (
			(nextStatus === PageStatus.focused && currStatus !== PageStatus.focused) ||
			(nextStatus === PageStatus.focused && !isPropsAndStateEqual)
		) {
			return true
		}
		return false
	}
	componentDidUpdate(prevProps) {
		if (prevProps.pageStatus !== PageStatus.willFocusFormBack) {
			setTimeout(() => this.firstNavigation.emitEvent("focus"), 0)
		}
	}
	render() {
		const { Component, pageProps = {}, params } = this.props

		const route = { key: "", name: this.props.routeName, params: this.navigation.params }

		overrideNavigationV4(this.navigation, route)
		return (
			<ErrorBoundaryWithFallback>
				<NavigationProvider navigation={this.navigation}>
					<Modal2Wrapper element={this.layerElement}>
						{this.renderToolbar}
						<Component
							{...params}
							{...pageProps}
							navigation={this.navigation}
							navigationOptions={this.navigationOptions}
						/>
						<div ref={this.layerElement} />
					</Modal2Wrapper>
				</NavigationProvider>
			</ErrorBoundaryWithFallback>
		)
	}
	setNavigation(props: any, params: ParsedUrlQuery, routeName: string) {
		const { Component } = props
		const { navigationOptions } = Component
		this.navigation = new ReactNavigationMapper(routeName, params, Router, this.updateNavigationState)
		this.navigationOptions =
			typeof navigationOptions === "function"
				? navigationOptions({ navigation: this.navigation } as any)
				: navigationOptions
	}
	private get renderToolbar() {
		if (!this.navigationOptions) {
			return null
		}
		const { title, headerStyle, headerTitleStyle, headerTitle, header } = this.navigationOptions
		if (typeof headerTitle === "string") {
			return headerTitle
		}

		if (typeof headerTitle === "function") {
			return headerTitle({} as HeaderTitleProps)
		}

		if (header) {
			return header as any
		}
		if (header === null) {
			return null
		}
		return (
			<Header.Wrap style={headerStyle as any}>
				<Header.Left>
					<ToolbarItem buttonIconType="back" onPress={() => this.navigation.goBack()} />
				</Header.Left>
				<Header.Center style={headerTitleStyle as any}>{title}</Header.Center>
				<Header.Right />
			</Header.Wrap>
		)
	}
}

const PageWrapper = styled(View)`
	background-color: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	flex: 1;
	max-width: 100%;
	/* box-shadow: -2px 0px 15px rgba(0,0,0,.25); */
`
const Header = {
	Wrap: styled.div`
		display: flex;
		text-align: center;
		min-height: ${ViewDimension.navigationHeaderHeight}px;
		padding: ${ViewDimension.statusBarHeight}px 10px 0;
		box-sizing: border-box;
		align-items: center;
		border-bottom: 1px solid #e5e5e5;
		z-index: 500;
	`,
	Left: styled.div`
		display: flex;
		flex: 0 0 80px;
		text-align: left;
	`,
	Center: styled.div`
		text-align: center;
		flex: 1;
	`,
	Right: styled.div`
		display: flex;
		flex: 0 0 80px;
		text-align: Right;
	`,
}
