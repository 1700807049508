import { NextRouter } from "next/router"
import React from "react"
import { ParamListBase, RouteProp } from "@react-navigation/native"
import { HomeStoreMapScreen } from "@zigbang/screens/home/store/HomeStoreMapScreen"
import { ReactNavigationMapper } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { SelectedMarker } from "@zigbang/screens/lib/WebMapStore"
import { StoreRouterName } from "@zigbang/screens/routes/store/types"
import { Rect } from "@zigbang/utils"

import { ContentLayer, DetailArea, Root } from "./AptMapOfferLayoutStyle"
import MapWrapper from "./MapWrapper"
import { PageContainer } from "./PageContainer"

export class StoreLayout extends React.Component<Props> {
	static layoutOptions = {
		noFooter: true,
	}

	static cloneInstance = (inst: any, additionalProps?: { [key: string]: any }) =>
		Object.assign(Object.create(Object.getPrototypeOf(inst)), inst, additionalProps)

	state: State = {
		rect: undefined,
		selectMarkerData: undefined,
		infoMarker: undefined,
	}
	dragging = false
	mapScreen: any = React.createRef()

	render() {
		const { navigation, rightScreen: Component, router, navigationRoute, routeName, ...restProps } = this.props
		let { query } = this.props
		if (!router || !router.pathname) {
			return null
		}
		if (this.props.router.pathname === "/home/store_items" || this.props.router.pathname === "/home/store_map") {
			query = { ...this.props.query, ...this.state.rect }
		}
		const pageContainer = (
			// @ts-ignore
			<PageContainer
				{...{ router, Component, routeName, ...restProps, ...query }}
				updateMarker={this.updateMarker}
				onHoverItemIn={this.onHoverItemIn}
				onHoverItemOut={this.onHoverItemOut}
			/>
		)
		return (
			<Root>
				<MapWrapper
					MapComponent={HomeStoreMapScreen}
					innerRef={this.mapScreen}
					navigation={navigation}
					route={navigationRoute}
					onPanDrag={this.onPanDrag}
					onRegionChangeComplete={this.onRegionChangeComplete}
					infoMarker={this.state.infoMarker}
					selectMarkerData={this.state.selectMarkerData}
				/>
				<DetailArea>
					<ContentLayer>{pageContainer}</ContentLayer>
				</DetailArea>
			</Root>
		)
	}

	onRegionChangeComplete = (rect: Rect) => {
		if (this.dragging) {
			if (this.props.router.pathname !== "/home/store_map") {
				this.props.navigation.push(StoreRouterName.HomeStoreMap)
			}
			this.dragging = false
		}
		this.setState({ rect })
	}

	onClickSearchResult = (item: { type: string; value: any }) => {
		//
	}
	onPanDrag = () => (this.dragging = true)

	onHoverItemIn = (marker: State["infoMarker"]) => {
		this.setState({ infoMarker: marker })
	}
	onHoverItemOut = () => {
		this.setState({ infoMarker: undefined })
	}

	updateMarker = ({ latitude, longitude, id, markerType, fixedZoom, exception, zoom }: SelectedMarker) => {
		const { isSSR } = this.props

		if (exception || !isSSR) {
			return
		}
		this.setState({ selectMarkerData: { latitude, longitude, id, markerType, fixedZoom, zoom } })
	}
}

interface Props {
	navigation: ReactNavigationMapper
	navigationRoute: RouteProp<ParamListBase>
	rightScreen: React.ComponentType<any>
	router: NextRouter
	asPath: string
	routeName: string
	query: { [key: string]: unknown }
	isSSR?: boolean
}

interface State {
	rect?: Rect
	selectMarkerData?: SelectedMarker
	infoMarker?: { element: JSX.Element; coordinate: { latitude: number; longitude: number }; key: number | string }
}
