import { StackNavigationOptions } from "@react-navigation/stack"
import { LayoutOptions } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"

import { MapType } from "../components/config"

export class SubRouter {
	router: Map<string, SubRouterOptions> = new Map()
	get(path: string) {
		return this.router.get(path)
	}
	set(path: string | string[], options: any) {
		if (Array.isArray(path)) {
			path.forEach((v) => this.router.set(v, options))
		}
		this.router.set(path as string, options)
	}
	has(path: string) {
		return this.router.has(path)
	}
}

export declare interface SubRouterOptions {
	layout: MapLayout<any> | MapLayoutFunc
	mapType?: MapType
}

export interface MapLayout<T> {
	navigationOptions?: StackNavigationOptions
	layoutOptions?: LayoutOptions
	new (props: T, context?: any): React.Component<T, any>
}

export declare type MapLayoutFunc = (mapType: MapType) => MapLayout<any>
