import React from "react"
import { ParamListBase, RouteProp } from "@react-navigation/native"
import { NextRouter } from "next/router"
import { HomeAptMapPopulationScreen } from "@zigbang/screens/home/apt/HomeAptMapPopulationScreen"
import { ReactNavigationMapper } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { NoSSR } from "@zigbang/screens/lib/NoSSR"
import { SelectedMarker, WebMapStore } from "@zigbang/screens/lib/WebMapStore"
import { MapArea, Root } from "./AptMapOfferLayoutStyle"

export class AptMapPopulationLayout extends React.Component<AptMapOfferLayoutProps> {
	static layoutOptions = {
		noFooter: true,
	}

	static cloneInstance = (inst: any, additionalProps?: { [key: string]: any }) =>
		Object.assign(Object.create(Object.getPrototypeOf(inst)), inst, additionalProps)
	fakeNavigation = AptMapPopulationLayout.cloneInstance(this.props.navigation, {
		setParams: () => {
			//
		},
	})

	state: AptMapOfferLayoutState = {
		selectMarkerData: undefined,
	}
	dragging = false
	mapScreen = React.createRef()
	mapstore = new WebMapStore()

	render() {
		const { rightScreen: Component, router } = this.props
		if (!router || !router.pathname) {
			return null
		}
		return (
			<Root>
				<MapWrapper
					MapComponent={HomeAptMapPopulationScreen}
					innerRef={this.mapScreen}
					navigation={this.fakeNavigation}
					selectMarkerData={this.state.selectMarkerData}
					detailExists={!Component.isEmpty}
				/>
			</Root>
		)
	}

	updateMarker = ({ latitude, longitude, id, markerType, fixedZoom, zoom }: SelectedMarker) => {
		this.setState({ selectMarkerData: { latitude, longitude, id, markerType, fixedZoom, zoom } })
	}

	setListParams = (params: { [key: string]: any }) => this.props.navigation.setParams({ list: params })

	setDetailParams = (params: { [key: string]: any }) =>
		this.props.navigation.setParams({ detail: { title: params.name || "로딩 중 입니다..." } })
}

class MapWrapper extends React.PureComponent<any> {
	render() {
		const { MapComponent, innerRef, ...props } = this.props
		return (
			<MapArea>
				<NoSSR>
					<MapComponent ref={innerRef} {...props} />
				</NoSSR>
			</MapArea>
		)
	}
}

interface AptMapOfferLayoutProps {
	navigation: ReactNavigationMapper
	rightScreen: React.ComponentType<any> & { isEmpty?: boolean }
	router: NextRouter
	routeName: string
	query: { [key: string]: unknown }
	area_danji_id?: string
	navigationRoute: RouteProp<ParamListBase>
}

interface AptMapOfferLayoutState {
	selectMarkerData?: SelectedMarker
}
