import { NextRouter } from "next/router"
import React from "react"
import { ParamListBase, RouteProp } from "@react-navigation/native"
import { StackNavigationOptions } from "@react-navigation/stack"
import { HomeOneroomMapScreen } from "@zigbang/screens/home/oneroom/HomeOneroomMapScreen"
import { LayoutOptions, ReactNavigationMapper } from "@zigbang/screens/lib/navigation/ReactNavigationMapper"
import { SelectedMarker, SelectedMarkerType } from "@zigbang/screens/lib/WebMapStore"
import { OneroomRouterName } from "@zigbang/screens/routes/oneroom/types"
import { Rect } from "@zigbang/utils"

import { ContentLayer, DetailArea, Root } from "./AptMapOfferLayoutStyle"
import MapWrapper from "./MapWrapper"
import { PageContainerV2 } from "./PageContainerV2"

export class OneroomLayout extends React.Component<Props> {
	static layoutOptions = {
		noFooter: true,
	}

	static cloneInstance = (inst: any, additionalProps?: { [key: string]: any }) =>
		Object.assign(Object.create(Object.getPrototypeOf(inst)), inst, additionalProps)

	state: State = {
		rect: undefined,
		selectMarkerData: undefined,
		infoMarker: undefined,
	}
	dragging = false
	mapScreen: any = React.createRef()

	render() {
		const { navigation, rightScreen: Component, router, navigationRoute, routeName, ...restProps } = this.props
		if (!router || !router.pathname) {
			return null
		}
		let { query } = this.props
		if (
			this.props.router.pathname === "/home/oneroom_items" ||
			this.props.router.pathname === "/home/oneroom_map" ||
			this.props.router.pathname === "/home/oneroom_subways_items"
		) {
			query = {
				...this.props.query,
				...this.state.rect,
			}
		}
		query.clusterSelectedTime = this.state.clusterSelectedTime

		const pageContainer = (
			<PageContainerV2
				{...{ router, Component, routeName, ...restProps }}
				query={query}
				asPath={this.props.asPath}
				updateMarker={this.updateMarker}
				onHoverItemIn={this.onHoverItemIn}
				onHoverItemOut={this.onHoverItemOut}
			/>
		)

		return (
			<Root>
				<MapWrapper
					MapComponent={HomeOneroomMapScreen}
					innerRef={this.mapScreen}
					navigation={navigation}
					route={navigationRoute}
					pathname={this.props.router.pathname}
					onPanDrag={this.onPanDrag}
					onRegionChangeComplete={this.onRegionChangeComplete}
					onClusterPress={this.onClusterPress}
					infoMarker={this.state.infoMarker}
					selectMarkerData={this.state.selectMarkerData}
				/>
				{/* <MapFilter searchResult={this.onClickSearchResult} mode={MapType.oneroom} /> */}
				<DetailArea>
					<ContentLayer>{pageContainer}</ContentLayer>
				</DetailArea>
			</Root>
		)
	}

	onRegionChangeComplete = (rect: Rect, zoom?: number) => {
		if (!this.dragging) {
			this.setState({
				rect,
				...(zoom ? { clusterSelectedTime: undefined } : undefined),
			})
			return
		}
		if (
			this.props.router.pathname !== "/home/oneroom_items" &&
			this.props.router.pathname !== "/home/oneroom_map"
		) {
			this.props.navigation.push(OneroomRouterName.HomeOneroomMap)
		}
		this.dragging = false
		this.setState({
			rect,
			clusterSelectedTime: undefined,
		})
	}

	onClickSearchResult = () => {
		//
	}
	onPanDrag = () => {
		this.dragging = true
	}

	onClusterPress = () => {
		this.setState({
			clusterSelectedTime: new Date().getTime(),
		})
	}

	onHoverItemIn = (marker: State["infoMarker"]) => {
		this.setState({ infoMarker: marker })
	}
	onHoverItemOut = () => {
		this.setState({ infoMarker: undefined })
	}

	updateMarker = ({
		latitude,
		longitude,
		id,
		markerType,
		fixedZoom,
		exception,
		zoom,
		shouldUpdateMap,
	}: SelectedMarker) => {
		const { isSSR, moveRegion, shouldUpdateMap: shouldUpdateMapFromNavigation } = this.props

		const shouldUpdateMarker =
			shouldUpdateMap || shouldUpdateMapFromNavigation || !((exception || !isSSR) && !moveRegion)

		if (shouldUpdateMarker) {
			this.setState({ selectMarkerData: { latitude, longitude, id, markerType, fixedZoom, zoom } })
		}
	}
}

interface Props {
	navigation: ReactNavigationMapper
	navigationRoute: RouteProp<ParamListBase>
	rightScreen: React.ComponentType<any> & {
		navigationOptions: StackNavigationOptions
		layoutOptions: LayoutOptions
	}
	router: NextRouter
	area_danji_id?: string
	routeName: string
	query: { [key: string]: unknown }
	asPath: string
	isSSR?: boolean
	moveRegion?: boolean
	shouldUpdateMap?: boolean
}

interface State {
	rect?: Rect
	selectMarkerData?: SelectedMarker
	infoMarker?: { element: JSX.Element; coordinate: { latitude: number; longitude: number }; key: number | string }
	clusterSelectedTime?: number
}
