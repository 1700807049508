class MapSubMenuStatus {
	get(mapType?: string) {
		if (typeof window === "undefined" && typeof mapType === "string") {
			return mapType
		}
		if (process.browser) {
			return localStorage ? localStorage.getItem("MAP_TYPE") || MapType.apt : MapType.apt
		}
	}
	update(type: MapType) {
		if (process.browser) {
			localStorage.setItem("MAP_TYPE", type)
		}
	}
	clear() {
		if (process.browser) {
			localStorage.removeItem("MAP_TYPE")
		}
	}
	from(type: string | string[]) {
		switch (type) {
			case "원룸":
				return MapType.oneroom
			case "아파트":
				return MapType.apt
			case "오피스텔":
				return MapType.officetel
			case "빌라":
				return MapType.villa
			case "상가":
				return MapType.store
			case "입주예정":
				return this.get() as MapType
			default:
				return MapType.apt
		}
	}
	validate(type: MapType | undefined) {
		if (!type) {
			return false
		}

		return Object.values(MapType).includes(type)
	}
}

export enum MapType {
	apt = "apt",
	offer = "offer",
	population = "population",
	villa = "villa",
	officetel = "officetel",
	oneroom = "oneroom",
	store = "store",
}

export const mapSubMenuStatus = new MapSubMenuStatus()
